<template>
  <div class="section-card">

    <div class="section-card-header">
      <strong class="section-card-header__title">{{$t('payment.schedule.title')}}</strong>
      <button class="section-card-header__edit" v-if="!cart.order.manual">{{$t('cta.planify')}}</button>
    </div>

    <div class="px-5 py-4">
      <div class="text-center mt-3 mb-6">
        <span v-if="!cart.preorder">{{$t('payment.schedule.now', {duration: cart.order.etaEstimated})}}</span>
        <span v-else-if="cart.preorder && !cart.preorder.preorderForEndRange">{{$t('payment.schedule.precise_date', {date: 'N/A', hour: 'xx'})}}</span>
        <span v-else-if="cart.preorder && cart.preorder.preorderForEndRange">{{$t('payment.schedule.range_date', {date: 'N/A', hour: 'xx', end: 'xx'})}}</span>
      </div>
      <small class="text-center text-gray-400 leading-tight block">{{$t('payment.schedule.etp_description')}}</small>
    </div>

  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

export default {
  data() {
    return {}
  },
  methods: {
    ...mapActions([])
  },
  computed: {
    ...mapGetters(['cart'])
  }
}
</script>
