<template>
  <div v-if="isOpen">
    <div class="modal-sheet__backdrop" @click="close"></div>
    <div class="modal-sheet">
      <div class="modal-sheet__header">
        <span class="title">Payer avec Google Pay</span>
        <div class="close__button" @click="close">
          <i class="material-icons notranslate">close</i>
        </div>
      </div>
      <div class="modal-sheet__action mt-2">
        <google-pay-button/>
        <div class="modal-price"><span>{{cart.order.priceWt.price}}</span></div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.modal-price
{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  span
  {
    font-size: 1.1em;
    color: #6b6b6b;
  }
}
</style>

<script>
import {mapGetters, mapActions} from 'vuex';
import GooglePayButton from "@/components/Checkout/Method/GooglePayButton";
import EventBus from "@/EventBus";

export default {
  components: {GooglePayButton},
  data() {
    return {
      isOpen: false
    }
  },
  methods: {
    ...mapActions([]),
    close(){
      this.isOpen = false;
    },
    open(){
      return new Promise(((resolve) => {
        this.isOpen = true;
        resolve({type: 'google_pay'});
      }));
    }
  },
  computed: {
    ...mapGetters(['cart'])
  },
    mounted() {
        EventBus.on('open:google-modal', () => {
            this.open()
        });
    }
}
</script>
