import { createStore } from 'vuex'
import CartStore from "@/store/CartStore";
import MerchantStore from "@/store/MerchantStore";
import PaymentMethodStore from "@/store/PaymentMethodStore";
import CheckoutStore from "@/store/CheckoutStore";

export default createStore({
  modules: {
    CartStore,
    MerchantStore,
    PaymentMethodStore,
    CheckoutStore
  }
})
