import { createApp } from 'vue'
import { createMetaManager } from 'vue-meta'


import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import vfmPlugin from "vue-final-modal";

import './assets/theme/tailwind.css'
import './assets/theme/base.scss'

import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

const globalTelOption = {
    defaultCountry: 'fr',
    inputOptions: {
        placeholder: 'Entrez votre numéro de téléphone'
    }
}

createApp(App)
    .use(store)
    .use(i18n)
    .use(createMetaManager())
    .use(router)
    .use(vfmPlugin)
    .use(VueTelInput, globalTelOption)
    .mount('#app')
