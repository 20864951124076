import { createI18n } from 'vue-i18n'

/* Vue i18n */
const i18n = createI18n({
  locale: 'fr',
  fallbackLocale: 'fr',
  messages: {
    fr: require('./locales/fr.json')
  }
})

export default i18n
