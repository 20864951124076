<template>
    <div class="section-card" id="payment_method">

        <div class="section-card-header">
            <strong class="section-card-header__title">{{$t('payment.payment.title')}}</strong>
            <button class="section-card-header__edit underline" v-if="selected === 'card' && cart.method" @click="openAddCardModal">{{$t('cta.update')}}</button>
            <button class="section-card-header__edit underline" v-if="selected === 'card' && !cart.method && hasWalletMethod" @click="openAddCardModal">{{$t('cta.add_card')}}</button>
        </div>

        <div class="px-5 py-4">

            <div class="flex flex-col gap-2" v-if="hasApplePay || hasGooglePay">

                <label for="p_apple_pay" class="payment-method-selector" v-if="hasApplePay">
                    <input type="radio" name="payment_method" value="apple_pay" v-model="selected" id="p_apple_pay" class="form-radio mr-3">
                    <div class="flex-1 text-sm">{{$t('payment.payment_type.apple_pay')}}</div>
                    <img src="/images/Apple_Pay_Mark_RGB_041619.svg" class="h-7" alt="">
                </label>

                <label for="p_google_pay" class="payment-method-selector" v-if="hasGooglePay">
                    <input type="radio" name="payment_method" value="google_pay" v-model="selected" id="p_google_pay" class="form-radio mr-3">
                    <div class="flex-1 text-sm">{{$t('payment.payment_type.google_pay')}}</div>
                    <img src="/images/google-pay.svg" class="h-7" alt="">
                </label>

                <label for="p_card" class="payment-method-selector" :class="{'bg-red-50 border border-red-200': errors.some(e => e.code === 'no_payment_method')}">
                    <input type="radio" name="payment_method" value="card" v-model="selected" id="p_card" class="form-radio mr-3">
                    <div class="flex-1 text-sm" v-if="cart.method">
                        ••••{{cart.method.last4}} {{cart.method.expiryDate}}
                    </div>
                    <div class="flex-1 text-sm" v-else>{{$t('payment.payment_type.card')}}</div>
                    <img src="/images/payment_method/amex.png" class="h-5" v-if="cart.method && cart.method.brand === 'amex'">
                    <img src="/images/payment_method/mastercard.png" class="h-5" v-if="cart.method && cart.method.brand === 'mastercard'">
                    <img src="/images/payment_method/visa.png" class="w-11" v-if="cart.method &&cart.method.brand === 'visa'">
                </label>
            </div>
            <div v-else>


                <div class="payment-method-selector" :class="{'bg-red-50 border border-red-200': errors.some(e => e.code === 'no_payment_method')}"  @click="openAddCardModal">
                    <div class="flex-1 text-sm flex items-center gap-2"  v-if="cart.method">
                        <span>••••{{cart.method.last4}}</span>
                        <span>{{cart.method.expiryDate}}</span>
                    </div>
                    <div class="flex-1 text-sm" v-else>{{$t('payment.payment_type.card')}}</div>

                    <button v-if="!cart.method && !hasWalletMethod" class="text-sm text-primary">{{$t('cta.add_card')}}</button>

                    <img src="/images/payment_method/amex.png" class="h-5" v-if="cart.method && cart.method.brand === 'amex'">
                    <img src="/images/payment_method/mastercard.png" class="h-5" v-if="cart.method && cart.method.brand === 'mastercard'">
                    <img src="/images/payment_method/visa.png" class="w-11" v-if="cart.method &&cart.method.brand === 'visa'">
                </div>

            </div>

            <small class="leading-tight block mt-2 text-red-600 font-medium" v-if="errors.some(e => e.code === 'no_payment_method')">Vous devez ajouter un moyen de paiement pour valider cette commande.</small>

            <div class="w-full" v-if="error">
                <small class="text-red-600 font-medium flex items-center justify-center mt-1">
                    <i class="material-icons text-sm mr-1">error</i>
                    {{error}}
                </small>
            </div>

            <div class="flex items-center justify-center text-center mt-4 text-gray-400">
                <i class="material-icons text-sm mr-1">lock</i>
                <span class="text-xs">{{$t('payment.payment_type.secure')}}</span>
            </div>
        </div>


        <ApplePayModal/>
        <GooglePayModal/>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import ApplePayModal from "@/components/Checkout/Modal/ApplePayModal";
import GooglePayModal from "@/components/Checkout/Modal/GooglePayModal";
import {loadStripe} from '@stripe/stripe-js';
import Config from "@/config/config";
import AddPaymentMethod from "@/components/Checkout/Modal/AddPaymentMethod";
import EventBus from "@/EventBus";

export default {
    components: {ApplePayModal, GooglePayModal},
    props: {
        errors: {
            type: Array
        }
    },
    data() {
        return {
            hasGooglePay: false,
            hasApplePay: false,
            selected: null,
            error: false
        }
    },
    methods: {
        ...mapActions([]),
        validatePaymentMethod(){
            if(this.selected === 'apple_pay'){
                return new Promise((resolve) => {
                    EventBus.emit('open:apple-modal');
                    resolve(true);
                });
            }
            else if(this.selected === 'google_pay'){
                return new Promise((resolve) => {
                    EventBus.emit('open:google-modal');
                    resolve(true);
                });
            }
            else if(this.selected === 'card'){
                return new Promise((resolve, reject) => {
                    if(this.cart.method && this.cart.method.type === 'card'){
                        return resolve({type: 'card'});
                    }
                    else{
                        return reject(false);
                    }
                });
            }
            else if(this.selected === null){
                return new Promise((resolve, reject) => {
                    this.error = this.$t('errors.no_payment_method_selected');
                    return reject('no_payment_method');
                });
            }
        },
        openAddCardModal(){
            this.$vfm.show({ component: AddPaymentMethod });
        },
    },
    computed: {
        ...mapGetters(['cart']),
        hasWalletMethod(){
            return (this.hasApplePay || this.hasGooglePay)
        }
    },
    watch: {
        selected(val){
            this.$store.commit('SET_PAYMENT_METHOD', val)
            if(val === 'card' && !this.cart.method && this.hasWalletMethod){
                this.openAddCardModal();
            }
            if(this.errors.length > 0){
                EventBus.emit('payment_method_added');
            }
        }
    },
    async mounted() {
        const stripe = await loadStripe(Config.stripePublishableKey);
        const PaymentRequest = await stripe.paymentRequest({
            country: 'FR',
            currency: 'eur',
            total: {
                label: 'Total',
                amount: 100,
            },
            requestPayerName: true,
            requestPayerEmail: true,
        });

        const pa = await PaymentRequest.canMakePayment();
        if(pa){
            this.hasGooglePay = pa.googlePay;
            this.hasApplePay = pa.applePay;
        }
        if(this.cart.method || !this.hasWalletMethod){
            this.selected = 'card';
        }
        else if(this.hasApplePay){
            this.selected = 'apple_pay';
        }

    }
}
</script>
