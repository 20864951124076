<template>

  <div v-if="isOpen" class="w-full">

    <div class="modal-sheet__backdrop" @click="close"></div>
    <div class="modal-sheet">
      <div class="modal-sheet__header">
        <span class="title">Payer avec Apple Pay</span>
        <div class="close__button" @click="close">
          <i class="material-icons notranslate">close</i>
        </div>
      </div>
      <div class="modal-sheet__action mt-2">
        <apple-pay-button/>
        <div class="modal-price"><span>{{cart.order.priceWt.price}}</span></div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.modal-price
{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  span
  {
    font-size: 1.1em;
    color: #6b6b6b;
  }
}
</style>

<script>
import {mapGetters, mapActions} from 'vuex';
import ApplePayButton from "@/components/Checkout/Method/ApplePayButton";
import EventBus from "@/EventBus";

export default {
  components: {ApplePayButton},
  data() {
    return {
      isOpen: false
    }
  },
  methods: {
    ...mapActions([]),
    close(){
      this.isOpen = false;
    },
      open(){
      return new Promise(((resolve) => {
        this.isOpen = true;
        resolve({type: 'apple_pay'});
      }));
    }
  },
  computed: {
    ...mapGetters(['cart'])
  },
    mounted() {
      EventBus.on('open:apple-modal', () => {
          this.open()
      });
    }
}
</script>
