import axios from "@/axios-instance";

export default {
    state:{
        cart: false,
        paymentMethod: ''
    },
    mutations:{
        SET_PAYMENT_METHOD(state, payload){
            state.paymentMethod = payload;
        },
        SET_CART(state, payload){
            state.cart = payload;
        },
        SET_CART_CONDITION(state, payload){
            state.cart.condition = payload;
        },
        SET_CART_METHOD(state, payload){
            state.cart.method = payload;
        },
        SET_CART_DELIVERY_ADDRESS(state, payload){
            state.cart.delivery.address = payload;
        }
    },
    getters:{
        paymentMethod: state => state.paymentMethod,
        cart: state => state.cart
    },
    actions: {
        getCartAction({commit}, {token}){
            return new Promise(((resolve, reject) => {
                axios.post('/api/client/v2/cart', {token:token})
                    .then((response) => {
                        commit('SET_CART', response.data.cart);
                        resolve(true);
                    })
                    .catch((error) => {
                        reject(error);
                    })
            }));
        },
        setCartPaymentCardAction({getters, commit}, {paymentToken}){
            return new Promise(((resolve, reject) => {
                axios.post('/api/client/v2/cart/update/payment_method', {orderToken: getters.cart.token, paymentToken: paymentToken})
                    .then((response) => {
                        commit('SET_CART_METHOD', response.data.method);
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    })
            }));
        },
        setCartCommentAction({getters, commit}, {comment}){
            return new Promise(((resolve, reject) => {
                axios.post('/api/client/v2/cart/update/comment', {orderToken: getters.cart.token, comment: comment})
                    .then((response) => {
                        commit('SET_CART', response.data.cart);
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    })
            }));
        },

        setCartAddressCommentAction({getters, commit}, {comment}){
            return new Promise(((resolve, reject) => {
                axios.post('/api/client/v2/cart/update/address_comment', {orderToken: getters.cart.token, comment: comment})
                    .then((response) => {
                        commit('SET_CART_DELIVERY_ADDRESS', response.data.cart);
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    })
            }));
        },

        setCartUserInfoAction({getters, commit}, {phone, email}){
            return new Promise(((resolve, reject) => {
                axios.post('/api/client/v2/cart/update/client_info', {orderToken: getters.cart.token, phone: phone, email: email})
                    .then((response) => {
                        commit('SET_CART', response.data.cart);
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    })
            }));
        }
    }
}
