<template>
  <div class="section-card">

    <div class="section-card-header">
      <strong v-if="cart.product" class="section-card-header__title">{{$t('payment.order.title_product', {count: 'N/A'})}}</strong>
      <strong v-else class="section-card-header__title">{{$t('payment.order.title')}}</strong>

      <button class="section-card-header__edit" v-if="!cart.order.manual" >{{$t('cta.update')}}</button>
    </div>

    <!-- Manual order -->
    <div class="py-4 px-5 border-b" v-if="cart.order.manual">
      <div class="flex justify-between items-start">
        <div class="flex flex-col">
          <span>{{$t('payment.order.order_line', {ref: cart.order.ref})}}</span>
          <small class="text-gray-400 text-xs">{{cart.order.comment}}</small>
        </div>
        <span>{{cart.order.priceProduct.price}}</span>
      </div>
    </div>

    <!-- Price list -->
    <div class="py-4 px-3">
      <div class="price-row" :class="{'font-bold': line.bold, 'price-row-discount': line.discount}" v-for="(line,i) in cart.receiptLines" :key="i">
        <span>{{line.name}}</span>
        <span :class="{'free-price': line.price.value === 0 && !line.discount}">{{ line.price.value > 0 ? line.price.price : $t('system.free') }}</span>
      </div>
    </div>

    <!-- CTA -->
    <div class="pb-4 px-5 flex justify-center items-center flex-col">
      <button class="button-action mb-1" :style="{'background-color': merchant.theme.primary}" v-if="!cart.order.manual">{{$t('payment.order.promo_code')}}</button>
      <button class="button-action-outline underline" @click="openOrderComment" :style="{'color': merchant.theme.primary}">{{$t('payment.order.add_comment')}}</button>
    </div>

  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import OrderComment from "@/components/Checkout/Modal/OrderComment";

export default {
  name: 'OrderSummary',
  data() {
    return {}
  },
  methods: {
    ...mapActions([]),
    openOrderComment(){
      this.$vfm.show({ component: OrderComment })
    }
  },
  computed: {
    ...mapGetters(['cart', 'merchant'])
  }
}
</script>
