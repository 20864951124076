<template>
  <vue-final-modal
      v-slot="{close, params}"
      transition="vfm-slide"
      :ssr="true"
      classes="flex justify-center items-end sm:items-center"
      content-class="w-full sm:w-[500px] bg-white rounded-t-2xl sm:rounded-2xl"
  >

    <div class="flex w-full justify-between items-center h-16 px-6 pt-5 mb-4">
      <span class="font-bold text-xl flex items-center text-gray-800">{{$t('payment.add_payment_method.title')}}</span>
      <button @click="close" class="flex items-center justify-center bg-gray-100 h-10 w-10 rounded-full"><i class="material-icons text-3xl">close</i></button>
    </div>

    <div class="w-full px-6 mb-4" v-if="params && params.error">
      <small class="text-red-600 font-medium flex items-start justify-center mt-1 leading-tight">
        <i class="material-icons text-sm mr-2">error</i>
        {{params.error}}
      </small>
    </div>


    <div class="px-6">
      <add-payment-method-form ref="paymentMethodForm" :save-button="!cart.order.manual" class="mb-6"/>
      <button :disabled="isButtonLoading" @click="save" class="action-button">
        <span v-if="!isButtonLoading">{{$t('cta.continue')}}</span>
        <span v-else>{{$t('system.stripe_loading')}}</span>
      </button>
    </div>


  </vue-final-modal>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import AddPaymentMethodForm from "@/components/Checkout/Method/AddPaymentMethodForm";
import {ref} from "vue";
import EventBus from "@/EventBus";

export default {
  components:{
    AddPaymentMethodForm
  },
  data() {
    return {
      isButtonLoading: false
    }
  },
  setup(){
    const paymentMethodForm = ref();
    return {paymentMethodForm};
  },
  methods: {
    ...mapActions(['setCartPaymentCardAction']),
    save(){
      this.isButtonLoading = true;
      this.paymentMethodForm.savePaymentMethod()
          .then(async (e) => {
            if(e.type === 'card'){
              await this.setCartPaymentCardAction({paymentToken: e.paymentMethodToken});
              EventBus.emit('payment_method_added');
              this.$vfm.hideAll();
            }
            this.isButtonLoading = false
          })
          .catch((e) => {
            console.log(e);
            this.isButtonLoading = false
          })
    }
  },
  computed: {
    ...mapGetters(['cart'])
  }
}
</script>
