<template>
  <div class="flex flex-col gap-3 py-2 relative">

    <div class="absolute flex justify-center items-center loading-glass z-40" v-if="isLoading">
      <app-loader type="loader"/>
    </div>

    <div class="w-full">
      <label class="form-label">{{$t('payment.payment_type.card_holder')}}</label>
      <input type="text" class="form-input" v-model="cardHolder" @input="validate()" :class="{'bg-red-50': errors.some(e => e.error === 'cardHolder')}" placeholder="ex: Pedro">
    </div>
    <div class="w-full flex flex-col sm:flex-row gap-3">
      <div class="w-full sm:w-1/2">
        <label class="form-label">{{$t('payment.payment_type.card_number')}}</label>
        <div class="form-input" ref="cardNumber"></div>
      </div>
      <div class="w-full flex sm:w-1/2 gap-3">
        <div class="w-1/2">
          <label class="form-label">{{$t('payment.payment_type.card_expiration')}}</label>
          <div class="form-input" ref="cardExpiration"></div>
        </div>
        <div class="w-1/2">
          <label class="form-label">{{$t('payment.payment_type.card_ccv')}}</label>
          <div class="form-input" ref="cardCCV"></div>
        </div>
      </div>
    </div>
      <div class="w-full" v-if="errors.some(e => e.error === 'cardNumber')">
          <small class="text-red-600 font-medium flex items-center gap-3 mt-1">
              <i class="material-icons text-base">error</i>
              {{errors.find(e => e.error === 'cardNumber').message}}
          </small>
      </div>


    <label class="flex items-center pl-2 mt-3" for="save-card" v-if="saveButton">
      <input type="checkbox" class="form-radio" id="save-card">
      <div class="flex-1 pl-3 leading-tight">
        <span class="text-sm">{{$t('payment.add_payment_method.save')}}</span>
      </div>
    </label>

  </div>
</template>

<style>
.loading-glass
{
  background: rgba( 255, 255, 255, 0.8 );
  backdrop-filter: blur( 2px );
  -webkit-backdrop-filter: blur( 2px );
  border-radius: 5px;
  left: -10px;
  right: -10px;
  bottom: -5px;
  top: -5px;
}
</style>

<script>
import {mapGetters, mapActions} from 'vuex';
import {loadStripe} from '@stripe/stripe-js';
import Config from "@/config/config";
import {ref} from "vue";
import AppLoader from "@/components/AppLoader";

const StripeElementStyle = {
  base: {
    color: '#000',
    fontWeight: 500,
    fontFamily: 'Inter',
    fontSize: '16px',
    fontSmoothing: 'antialiased',
    ':focus': {
      color: '#222',
    },
    '::placeholder': {
      color: '#9ba2b0',
    },
    ':focus::placeholder': {
      color: '#a6a6a6',
    },
  },
  invalid: {
    color: '#eb1c26',
    backgroundColor: '#fef0ef',
    ':focus': {
      color: '#eb1c26',
    },
    '::placeholder': {
      color: '#ffbdbd',
    },
  },
};

const StripeElementClasses = {
  invalid: 'bg-red-50',
};

export default {
  props: ['saveButton'],
  setup(){
    const cardNumber = ref();
    const cardExpiration = ref();
    const cardCCV = ref();

    return {cardNumber, cardExpiration, cardCCV};
  },
  data() {
    return {
      cardHolder: '',
      stripe: false,
      element: false,
      elementInputs: false,
      errors: [],
      isLoading: false
    }
  },
  components: {AppLoader},
  methods: {
    ...mapActions(['savePaymentMethodAction']),

    validate(){
      this.errors = [];
      if(!this.cardHolder){
        this.errors.push({error: 'cardHolder'});
      }
      if(this.errors.length > 0){
        return false;
      }
      return true;
    },

    savePaymentMethod(){
      return new Promise(((resolve, reject) => {
        if(!this.validate()){
          return reject(false);
        }

        let cardHolderInfos = {};
        if(this.payment && this.cart.delivery){
          cardHolderInfos = {
            name: this.cardHolder,
            address_line1: this.cart.delivery.address.address,
            address_city: this.cart.delivery.address.city,
            address_zip:  this.cart.delivery.address.zip,
            address_country: this.cart.delivery.address.countryCode
          };
        }
        else{
          cardHolderInfos = {
            name: this.cardHolder
          };
        }

        this.isLoading = true;
        this.stripe.createToken(this.elementInputs[0], cardHolderInfos)
            .then((result) =>  {
              if (result.token) {
                const paymentMethodToken = result.token.id;
                this.savePaymentMethodAction({paymentMethodToken: paymentMethodToken})
                    .then((data) => {
                      resolve({type: 'card', paymentMethodToken: data.pm_id});
                    })
                    .catch((e) => {
                        if(e.response && e.response.data){
                            if(this.$te('errors.'+e.response.data.message)){
                                this.errors.push({error: 'cardNumber', message: this.$t('errors.'+e.response.data.message)});
                            }
                            else{
                                this.errors.push({error: 'cardNumber', message: this.$t('errors.payment_method_error')});
                            }
                        }
                      console.log(e);
                      if(this.$te('errors.'+e)){
                        this.errors.push({error: 'cardNumber', message: this.$t('errors.'+e)});
                      }
                      else{
                        this.errors.push({error: 'cardNumber', message: e});
                      }
                      this.isLoading = false;
                      reject(e);
                    });
              }
              if(result.error){
                this.errors.push({error: 'cardNumber', message: result.error.message});
                this.isLoading = false;
                reject(false);
              }
            })
            .catch((error) => {
              this.isLoading = false;
              reject(error);
            });
      }));
    },
  },
  computed: {
    ...mapGetters(['cart'])
  },
  async mounted() {
    this.isLoading = false;

    this.stripe = await loadStripe(Config.stripePublishableKey);
    this.element = this.stripe.elements({
      fonts: [{
        cssSrc: 'https://fonts.googleapis.com/css?family=Inter:400'
      }]
    });
    var cardNumber =  this.element.create('cardNumber', {
      style: StripeElementStyle,
      classes: StripeElementClasses
    });
    cardNumber.mount(this.cardNumber);

    var cardExpiry =  this.element.create('cardExpiry', {
      style: StripeElementStyle,
      classes: StripeElementClasses,
    });
    cardExpiry.mount(this.cardExpiration);

    var cardCvc =  this.element.create('cardCvc', {
      style: StripeElementStyle,
      classes: StripeElementClasses,
    });
    cardCvc.mount(this.cardCCV);

    this.elementInputs = [cardNumber, cardExpiry, cardCvc];
  }
}
</script>
