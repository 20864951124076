<template>
  <div class="section-card">
    <div class="section-card-header">
      <strong class="section-card-header__title">{{$t('payment.delivery.title')}}</strong>
      <button class="section-card-header__edit" v-if="!cart.order.manual">{{$t('cta.update')}}</button>
    </div>
    <div class="px-5 py-4 flex flex-col">
      <span>{{cart.delivery.address.address}}</span>
      <span>{{cart.delivery.address.zip}} {{cart.delivery.address.city}}</span>
      <span class="text-sm mt-2" v-if="cart.delivery.address.comment"><strong>{{$t('payment.delivery.indications')}} :</strong> {{cart.delivery.address.comment}}</span>
    </div>
    <div class="px-5 py-4 pt-0 flex flex-col justify-center items-center">
      <button class="button-action mb-1 underline" :style="{'background-color': merchant.theme.primary}" v-if="cart.manual">{{$t('payment.delivery.switch-in-pickup')}}</button>
      <button class="button-action-outline underline" @click="addressComment" :style="{'color': merchant.theme.primary}">{{$t('payment.delivery.address_comment')}}</button>
    </div>

  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import AddressComment from "@/components/Checkout/Modal/AddressComment";

export default {
  data() {
    return {}
  },
  methods: {
    ...mapActions([]),
    addressComment(){
      this.$vfm.show({ component: AddressComment })
    }
  },
  computed: {
    ...mapGetters(['cart', 'merchant'])
  }
}
</script>
