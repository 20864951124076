<template>
  <div>
    <div class="payment-loading" v-if="isLoading">
      <app-loader/>
    </div>
    <div v-show="!isLoading">
      <div id="payment-request-button-apple"></div>
    </div>
    <div class="payment-button-error" v-if="error">
      <span v-if="error && $te('errors.'+error)">{{$t('errors.'+error)}}</span>
      <span v-if="error && !$te('errors.'+error)">{{$t('errors.error_please_try_again')}}</span>
    </div>
  </div>
</template>

<style lang="scss">
.payment-button-error
{
  margin-top: 10px;
  span
  {
    color: #be0000;
  }
}
.payment-loading
{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

<script>
import {mapGetters, mapActions} from 'vuex';
import config from "@/config/config";
import {loadStripe} from '@stripe/stripe-js';
import AppLoader from "@/components/AppLoader";

export default {
  data() {
    return {
      error: false,
      isLoading: true,
      stripe: false
    }
  },
  components: {AppLoader},
  methods: {
    ...mapActions(['walletPreRequestAction', 'walletCompletePaymentAction']),
    endPayment(){
      this.walletCompletePaymentAction()
          .then((data) => {
            if(data.redirect){
              window.location.href = data.redirect;
            }
            else if(data.next_action){
              window.location.href = data.next_action.redirect_url;
            }
          })
          .catch((e) => {
            console.log('Error', e);
            this.error = 'error_please_try_again';
          })
    }
  },
  computed: {
    ...mapGetters(['cart'])
  },
  async mounted() {
    const stripe = await loadStripe(config.stripePublishableKey);

    this.error = false;
    this.isLoading = true;

    const paymentRequest = stripe.paymentRequest({
      country: 'FR',
      currency: 'eur',
      total: {
        label: 'Total',
        amount: this.cart.order.priceWt.value,
      },
      requestPayerName: true,
      requestPayerEmail: true,
      disableWallets: ['googlePay', 'browserCard']
    });

    const elements = await stripe.elements();
    const prButton = await elements.create('paymentRequestButton', {paymentRequest});


    const result = await paymentRequest.canMakePayment();
    if (result) {
      await prButton.mount('#payment-request-button-apple');
      this.isLoading = false;
    } else {
      this.isLoading = false;
      document.getElementById('payment-request-button-apple').style.display = 'none';
    }


    paymentRequest.on('paymentmethod', async (ev) => {
      this.isLoading = true;
      const backendData = await this.walletPreRequestAction({type: 'stripe_apple_pay'})
          .catch(() => {
            this.isLoading = false;
          });

      const {paymentIntent, error: confirmError} = await stripe.confirmCardPayment(
          backendData.paymentIntent,
          {payment_method: ev.paymentMethod.id},
          {handleActions: false}
      );
      console.log(paymentIntent, backendData, ev);


      if (confirmError) {
        ev.complete('fail');
      } else {
        ev.complete('success');
        if (paymentIntent.status === "requires_action") {

          const {error} = await stripe.confirmCardPayment(backendData.paymentIntent);
          if (error) {
            this.error = 'error_please_change_payment_method';
          } else {
            this.endPayment();
          }

        } else {
          this.endPayment();
        }
      }

      this.isLoading = false;
    });


  }
}
</script>
