export default {
  baseUrl: 'https://api.delicity.co',
  loginUrl: 'http://login.delicity.co',
  authClientSecret: '5CbbzZQBDEfk3CG0sCc0DQcTmsYgIPoKPRmlcTLr',
  authClientId: 2,
  googleMapsApi: 'AIzaSyDpXzBs2E_B99XMM10Ji474-zfPPKSDXa0',
  echoAuthUrl: 'https://login.delicity.co/api/broadcasting/auth',
  pusherKey: '47b9ed1beb0bc4761378',
  googleAndroidClient: "223371090008-lt75d1u33kslt560qoktf1bssfk4m6p4.apps.googleusercontent.com",
  stripePublishableKey: "pk_live_lkeGCwnoZ9j0UJjsYtT9JGI400pgQt5TJW",
  environment: "production"
}
