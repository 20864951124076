<template>
  <div class="section-card">

    <div class="section-card-header">
      <strong class="section-card-header__title">{{$t('payment.user_infos.title')}}</strong>
      <button class="section-card-header__edit underline" @click="update">{{$t('cta.update')}}</button>
    </div>

    <div class="flex flex-col justify-center items-center p-5">
      <div class="mb-2 h-16 w-16 bg-gray-200 rounded-full bg-center bg-cover" :style="{'background-image': 'url('+cart.client.avatar.path+')'}"></div>
      <strong>{{cart.client.name}}</strong>
      <span class="text-sm">{{cart.client.phoneParsed}}</span>
      <span class="text-sm" v-if="cart.client.email">{{cart.client.email}}</span>
      <span class="text-xs text-gray-400" v-else>{{$t('payment.user_infos.no_email')}}</span>

      <span class="text-xs text-center text-gray-400 mt-5 px-6" v-if="cart.order.manual">{{$t('payment.user_infos.email_description')}}</span>
    </div>

  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import OrderUserInfos from "@/components/Checkout/Modal/OrderUserInfos";

export default {
  data() {
    return {}
  },
  methods: {
    ...mapActions([]),
    update(){
      this.$vfm.show({ component: OrderUserInfos })
    }
  },
  computed: {
    ...mapGetters(['cart'])
  }
}
</script>
