<template>
    <div class="w-full sm:py-5 sm:bg-[#DADEE1] min-h-[100vh]" v-if="!isCartLoading">
        <div class="container mx-auto min-h-[500px] sm:rounded-[15px] md:max-w-[700px] overflow-hidden bg-gray-50 sm:flex sm:flex-col items-center sm:bg-[#F7F8F9]">
            <merchant-header/>

            <div class="py-4 px-6 pb-36 sm:pb-14 sm:w-[550px] md:my-5">
                <order-delivery v-if="cart.delivery"/>
                <order-schedule v-if="cart.order.manual === 0 || cart.order.manual === 0 && cart.delivery"/>
                <order-summary/>
                <order-client v-if="cart.client"/>
                <order-payment-method :errors="errors.filter(e => e.error === 'payment_method')" ref="paymentMethods"/>
                <order-conditions :errors="errors"/>
                <!-- Desktop validate button -->
                <div class="w-full mt-10 flex justify-center" >
                    <validate-button class="hidden sm:flex rounded-lg" id="main-validate-button" :is-loading="isLoading" :price="cart.order.priceWt.price" :disabled="checkoutDisabled" @click="checkout"></validate-button>
                </div>
            </div>
        </div>
    </div>

  <!-- Mobile fixed validate button -->
    <div class="w-full fixed bottom-0 sm:hidden z-50 bg-white" v-if="!isCartLoading">
        <validate-button class="flex sm:hidden" :is-loading="isLoading" :price="cart.order.priceWt.price" :disabled="checkoutDisabled" @click="checkout"></validate-button>
    </div>

    <div class="hidden fixed bottom-0 left-0 right-0 md:flex justify-center items-center transform transition-all duration-200" :class="{'mb-[-100px]': !isSmallValidateButtonDisplayed}">
        <button class="bg-[#36c46d] px-10 py-3 rounded-full mb-5 text-white drop-shadow-2xl disabled:opacity-75" @click="checkout" :disabled="isCartLoading">
            <div class="flex-1 flex uppercase items-center font-bold justify-end" :class="{'opacity-50': checkoutDisabled}" v-if="!isCartLoading">
                <span class="uppercase font-bold">{{$t('cta.validate')}}</span>
                <i class="material-icons ml-2">arrow_forward</i>
            </div>
            <div v-else>
                <app-loader class="h-6"></app-loader>
            </div>
        </button>
    </div>


  <!-- Payment Error -->
    <div class="h-[100vh] w-full flex items-center justify-center p-10 flex-col text-center" v-if="isCartLoading && error">
        <img :src="'/images/'+errorImage" v-if="errorImage" class="w-28 h-28">
        <span class="mt-5 font-medium text-red-600">{{error}}</span>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import OrderPaymentMethod from "@/components/Checkout/PaymentMethod";
import OrderSummary from "@/components/Checkout/Products";
import OrderDelivery from "@/components/Checkout/Delivery";
import OrderClient from "@/components/Checkout/ClientInfos";
import OrderConditions from "@/components/Checkout/Conditions";
import OrderSchedule from "@/components/Checkout/Schedule";
import {ref} from "vue";
import ValidateButton from "@/components/Checkout/ValidateButton";
import MerchantHeader from "@/components/Checkout/MerchantHeader";
import AddPaymentMethod from "@/components/Checkout/Modal/AddPaymentMethod";
import AppLoader from "@/components/AppLoader";
import EventBus from "@/EventBus";

export default {
    name: 'PaymentPage',
    components: {OrderPaymentMethod, OrderSummary, OrderDelivery, OrderClient, OrderConditions, ValidateButton, MerchantHeader, OrderSchedule, AppLoader},
    setup(){
        const paymentMethods = ref();
        return {paymentMethods};
    },
    data() {
        return {
            errorImage: false,
            error: false,
            isLoading: false,
            isSmallValidateButtonDisplayed: false,
            errors: []
        }
    },
    methods: {
        ...mapActions(['getCartAction', 'checkoutCardAction', 'getMerchantAction']),

        validate(){
            this.errors = [];

            if(this.cart.condition === false){
                this.errors.push({error: 'condition'});
                window.scrollTo({
                    top: document.getElementById('condition').offsetTop + 100,
                    left: 0,
                    behavior: 'smooth'
                });
            }

            if(this.cart.method === null && this.paymentMethod === 'card'){
                this.errors.push({error: 'payment_method', code: 'no_payment_method'});
                window.scrollTo({
                    top: document.getElementById('payment_method').offsetTop + 100,
                    left: 0,
                    behavior: 'smooth'
                });
            }

            return this.errors.length === 0;
        },

        async checkout(){
            if(!this.validate()){
                return;
            }

            this.isLoading = true;
            this.paymentMethods.validatePaymentMethod()
                .then((data) => {
                    if(data.type === 'card'){
                        this.checkoutCardAction()
                            .then((data) => {
                                if(data.redirect){
                                    window.location.href = data.redirect;
                                }
                                else if(data.next_action){
                                    window.location.href = data.next_action.redirect_url;
                                }
                            })
                            .catch((error) => {
                                this.isLoading = false;
                                if(error.response.data.message === 'bad_manual_payment_token'){
                                    this.$vfm.show({component: AddPaymentMethod}, {error: this.$t('errors.payment_method_expire') });
                                    this.$store.commit('SET_CART_METHOD', null);
                                }
                            })
                    }
                    else{
                        setTimeout(() => {
                            this.isLoading = false;
                        }, 1000)
                    }
                })
                .catch(() => {
                    this.isLoading = false;
                });
        }
    },
    computed: {
        ...mapGetters(['cart', 'merchant', 'paymentMethod']),
        isCartLoading(){
            return !this.cart || !this.merchant;
        },
        checkoutDisabled(){
            return this.isLoading;
        }
    },
    async mounted() {
        EventBus.on('payment_method_added', () => {
            this.validate();
        })

        await this.getCartAction({token: this.$route.params.token})
            .catch((error) => {
                if(error.response.data.message && this.$te('errors.'+ error.response.data.message)){
                    this.error = this.$t('errors.' + error.response.data.message);
                    this.errorImage = 'error_circle.png';
                }
                if(error.response.status === 404){
                    this.errorImage = 'error.png';
                    this.error = this.$t('errors.payment_not_found');
                }
                if(error.response.status === 403){
                    this.errorImage = 'error_circle.png';
                    this.error = this.$t('errors.payment_expired');
                }
            });
        if(this.cart){
            await this.getMerchantAction({slug: this.cart.merchant.slug})
        }


        this.$nextTick(() => {
            const button = document.getElementById('main-validate-button');
            this.mainValidateButtonOffsetTop = button.offsetTop;
            this.isSmallValidateButtonDisplayed = this.mainValidateButtonOffsetTop >= window.scrollY + window.innerHeight - (button.offsetHeight / 2);
            document.body.onscroll = () => {
                this.isSmallValidateButtonDisplayed = this.mainValidateButtonOffsetTop >= window.scrollY + window.innerHeight - (button.offsetHeight / 2);
            };

        });

    }
}
</script>
