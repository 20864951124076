<template>
  <button class=" p-3 validate-button text-white w-full  items-center px-6 disabled:opacity-75">
    <div class="flex-1 flex flex-col items-start" v-if="!isLoading">
      <span class="text-[10px] font-bold uppercase leaning-none">{{$t('payment.button.total')}}</span>
      <strong class="text-2xl font-medium leaning-none">{{price}}</strong>
    </div>
    <div class="flex-1 flex items-center font-bold justify-end" :class="{'opacity-50': disabled}" v-if="!isLoading">
      <span class="font-bold text-xl">{{$t('cta.validate')}}</span>
      <i class="material-icons ml-2">arrow_forward</i>
    </div>
    <div class="flex-1 flex justify-center items-center h-[42px]"  v-if="isLoading">
      <app-loader></app-loader>
    </div>
  </button>
</template>

<style class="" lang="scss">
.validate-button
{
  background-color: #36c46d;
}
.validate-button:enabled
{
  animation: btnAnimation 1.5s ease-in-out infinite;
  background-image: linear-gradient(130deg, rgba(#fff,0) 30%, rgba(#fff,0.2) 50%, rgba(#fff,0));
  background-repeat: no-repeat;
}
@keyframes btnAnimation {
  from {
    background-position-x: -150vw;
  }
  to {
    background-position-x: 150vw;
  }
}
</style>

<script>
import {mapGetters, mapActions} from 'vuex';
import AppLoader from "@/components/AppLoader";
export default {
  props: ['isLoading', 'price', 'disabled'],
  components: {AppLoader},
  data() {
    return {}
  },
  methods: {
    ...mapActions([]),
  },
  computed: {
    ...mapGetters([])
  }
}
</script>
