import axios from "@/axios-instance";

export default {
    state:{

    },
    mutations:{

    },
    getters:{

    },
    actions: {
        checkoutCardAction({getters}){
            return new Promise(((resolve, reject) => {
                axios.post('/api/client/v2/checkout/execute', {order_token: getters.cart.token, manualPaymentMethodToken: localStorage.getItem('manualPaymentMethodToken')})
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    })
            }));
        },
        walletPreRequestAction({getters}, {type}){
            return new Promise(((resolve, reject) => {
                axios.post('/api/client/v2/checkout/walletPreRequest', {orderToken: getters.cart.token, type: type})
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    })
            }));
        },
        walletCompletePaymentAction({getters}){
            return new Promise(((resolve, reject) => {
                axios.post('/api/client/v2/checkout/walletCompletePayment', {orderToken: getters.cart.token})
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    })
            }));
        }
    }
}
