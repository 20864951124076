<template>
  <div class="h-[100vh] w-full flex items-center justify-center p-10 flex-col text-center">
    <img src="/images/page-not-found.png"  class="w-36 h-36">
    <span class="mt-5 font-medium text-red-600">{{$t('errors.no_payment_page')}}</span>
  </div>

</template>

<script>
import {mapGetters, mapActions} from 'vuex';

export default {
  data() {
    return {}
  },
  methods: {
    ...mapActions([])
  },
  computed: {
    ...mapGetters([])
  },
  mounted() {
    setTimeout(() => {
      window.location.href = 'https://delicity.com/'
    }, 3000)
  }
}
</script>
