import { createRouter, createWebHistory } from 'vue-router'

import ErrorPage from "@/views/ErrorPage";
import CheckoutPage from "@/views/CheckoutPage";

const routes = [
  {
    path: '/',
    name: 'error',
    component: ErrorPage
  },
  {
    path: '/:token',
    name: 'checkout',
    component: CheckoutPage
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
