import axios from "@/axios-instance";


export default {
    state: {

    },
    mutations: {

    },
    getters: {

    },
    actions: {
        savePaymentMethodAction({getters}, {paymentMethodToken}){
            return new Promise(((resolve, reject) => {
                axios.post('/api/client/v2/payment_method/create', {payment_method_token: paymentMethodToken, order_token: getters.cart.token})
                    .then((response) => {
                        localStorage.setItem('manualPaymentMethodToken', response.data.pm_id);
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error.response.data.message ?? error.message);
                    })
            }));
        },
    }
}
