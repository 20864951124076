<template>
  <vue-final-modal
      v-slot="{close}"
      transition="vfm-slide"
      :ssr="true"
      classes="flex justify-center items-end sm:items-center"
      content-class="w-full sm:w-[500px] bg-white rounded-t-2xl sm:rounded-2xl"
  >

    <div class="flex w-full justify-between items-center h-16 px-6 pt-5 mb-4">
      <span class="font-bold text-xl flex items-center text-gray-800">{{$t('payment.order.add_comment')}}</span>
      <button @click="close" class="flex items-center justify-center bg-gray-100 h-10 w-10 rounded-full"><i class="material-icons text-3xl">close</i></button>
    </div>

    <div class="px-6">
      <label class="form-label mb-2">{{$t('payment.order.your_comment')}}</label>
      <textarea type="text" v-model="comment" class="form-input min-h-[100px] mb-5" :placeholder="$t('payment.order.your_comment_placeholder')"></textarea>

      <button :disabled="isButtonLoading" @click="save" class="action-button">
        <span v-if="!isButtonLoading">{{$t('cta.save')}}</span>
        <app-loader class="h-5" v-else/>
      </button>
    </div>


  </vue-final-modal>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import AppLoader from "@/components/AppLoader";

export default {
  data() {
    return {
      comment: '',
      isButtonLoading: false
    }
  },
  components: {AppLoader},
  methods: {
    ...mapActions(['setCartCommentAction']),
    async save(){
      this.isButtonLoading = true;
      await this.setCartCommentAction({comment: this.comment})
      this.$vfm.hideAll();
      this.isButtonLoading = false;
    }
  },
  computed: {
    ...mapGetters(['cart'])
  },
  mounted() {
    this.comment = this.cart.order.comment;
  }
}
</script>
