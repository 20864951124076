<template>
  <div class="h-[130px] sm:h-[150px] bg-cover bg-center flex w-full" :style="{'background-image':'url('+merchant.banner.path+')'}">
    <div class="bg-black/30 w-full h-full px-4 flex items-center sm:justify-center sm:text-center">
      <div class="mt-10  flex flex-col">
        <h1 class="text-white font-bold text-2xl sm:text-3xl leading-tight">{{merchant.name}}</h1>
        <span class="text-white font-medium text-lg leading-tight">{{$t('payment.title')}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

export default {
  data() {
    return {}
  },
  methods: {
    ...mapActions([])
  },
  computed: {
    ...mapGetters(['merchant'])
  }
}
</script>
