<template>
  <div class="flex items-center justify-between px-2 leaning-none text-grey-800">
    <label for="condition" class="text-sm sm:text-base" :class="{'text-red-600': errors.some(e => e.error === 'condition') && !isChecked}">{{$t('payment.condition.1')}} <a href="https://pro.delicity.com/cgv/" class="underline font-medium" target="_blank">{{$t('payment.condition.2')}}</a> {{$t('payment.condition.3', {merchant: merchant.name})}}</label>

    <div class="flex items-center pl-4" id="condition">
      <label for="toggle" class="flex items-center cursor-pointer">
        <div class="relative rounded-full overflow-hidden">
          <input v-model="isChecked" @change="updateCondition" id="toggle" type="checkbox" class="sr-only" />
          <div class="bar transition w-12 h-7 bg-gray-400 rounded-full shadow-inner" :class="{'bg-red-600': errors.some(e => e.error === 'condition')}"></div>
          <div class="dot absolute w-6 h-6 bg-white rounded-full top-[0.15rem] left-[0.1rem] transition"></div>
        </div>
      </label>
    </div>

  </div>
</template>

<style>
#toggle:checked ~ .dot {
  transform: translateX(1.25rem);
  background-color: #fff;
}
#toggle:checked ~ .bar {
  background-color: #49dc7f;
}
.dot
{
  box-shadow: 0 2px 3px 2px rgba(0,0,0,0.1);
}
</style>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
  props: ['errors'],
  data() {
    return {
      isChecked: false
    }
  },
  methods: {
    ...mapActions([]),
    updateCondition() {
      this.$store.commit('SET_CART_CONDITION', this.isChecked);
    }
  },
  computed: {
    ...mapGetters(['merchant'])
  }
}
</script>
