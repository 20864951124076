<template>
  <vue-final-modal
      v-slot="{close}"
      transition="vfm-slide"
      :ssr="true"
      classes="flex justify-center items-end sm:items-center"
      content-class="w-full sm:w-[500px] bg-white rounded-t-2xl sm:rounded-2xl"
  >

    <div class="flex w-full justify-between items-center h-16 px-6 pt-5 mb-4">
      <span class="font-bold text-xl flex items-center text-gray-800">{{$t('payment.user_infos.title')}}</span>
      <button @click="close" class="flex items-center justify-center bg-gray-100 h-10 w-10 rounded-full"><i class="material-icons text-3xl">close</i></button>
    </div>

    <div class="px-6">
      <label class="form-label mb-2">{{$t('payment.user_infos.phone')}}</label>
      <vue-tel-input @on-input="updatePhone" v-model="phone"
                     :inputOptions="{type: 'tel', placeholder:$t('payment.user_infos.phone_placeholder')}"
                     mode="international"
                     class="mb-3"
                     :class="{'vue-tel-input-error': errors.some(e=>e.error === 'phone' &&e.message)}"/>

      <label class="form-label mb-2 mt-5">{{$t('payment.user_infos.email')}}</label>
      <input type="text" :class="{'vue-tel-input-error': errors.some(e=>e.error === 'email' &&e.message)}" v-model="email" class="form-input" :placeholder="$t('payment.user_infos.email_accounting_placeholder')">

      <button class="action-button mt-20" @click="save">{{$t('cta.save')}}</button>
    </div>


  </vue-final-modal>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

export default {
  data() {
    return {
      phone: '',
      email: '',
      isButtonLoading: false,
      errors: []
    }
  },
  methods: {
    ...mapActions(['setCartUserInfoAction']),
    validate(){
      this.errors = [];
      if(this.phone === ''){
        this.errors.push({error: 'phone', message: this.$t('errors.phone_required')});
      }
      if(this.errors.length > 0){
        return false;
      }
      return true;
    },
    async save(){
      if(!this.validate()){
        return;
      }
      this.isButtonLoading = true;
      await this.setCartUserInfoAction({phone: this.phone, email: this.email});
      this.$vfm.hideAll();
      this.isButtonLoading = false;
    },
    updatePhone(e,v){
      if(v.valid === true){
        this.phone = v.formatted;
      }
    },
  },
  computed: {
    ...mapGetters(['cart'])
  },
  mounted() {
    this.phone = this.cart.client.phone;
    this.email = this.cart.client.email;
  }
}
</script>
