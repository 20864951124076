import axios from "@/axios-instance";

export default {
    state: {
        merchant: false
    },
    mutations: {
        SET_MERCHANT(state, merchant) {
            state.merchant = merchant
        }
    },
    getters: {
        merchant: state => state.merchant
    },
    actions: {
        getMerchantAction({commit}, {slug}){
            return new Promise(((resolve, reject) => {
                axios.get('/api/client/v2/merchant/' + slug)
                    .then((response) => {
                        commit('SET_MERCHANT', response.data.merchant);
                        resolve(true);
                    })
                    .catch(() => {
                        reject(false);
                    })
            }));
        }
    }
}
